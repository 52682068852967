<template>
  <div
    ref="dropdownRoot"
    @click="toggleDropdown"
    :class="['checkbox-dropdown--root', dropdownActive && 'active']"
  >
    <Checkbox
      @click="checkboxClicked"
      :model-value="modelValue"
      :disabled="disabled"
      :intermediate="checkboxIntermediate"
    />
    <Icon
      class="chevron-icon"
      :name="dropdownActive ? 'chevron-up' : 'chevron-down'"
    />
    <div :class="['dropdown-root', dropdownActive && 'active']">
      <template v-if="!multiselect">
        <div
          v-for="action in actions"
          :key="action.key"
          :class="{
            'dropdown-item': true,
            'active': selectedValue === action.key && modelValue,
            'disabled': action.disabled,
          }"
          @click="onValueSelected(action)"
        >
          {{ action.label }}
        </div>
      </template>
      <template v-else>
        <div
          v-for="action in actions"
          :key="action.key"
          :class="{
            'dropdown-item': true,
            'disabled': action.disabled,
          }"
          @click="onValueSelected(action)"
        >
          {{ action.label }}
          <Icon
            v-if="action.selected"
            name="check"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Checkbox from './Checkbox';
import Icon from './Icon';

export default {
  emits: ['selected:value'],
  components: {
    Checkbox,
    Icon,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    selectedItemsLength: {
      type: Number,
      default: () => 0,
    },
    rowsLength: {
      type: Number,
      default: () => 0,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dropdownRoot = ref(null);
    const dropdownActive = ref(false);
    const selectedValue = ref(null);
    const selectedItems = ref([]);
    const modelValue = computed(() => !!props.selectedItemsLength);
    const checkboxIntermediate = computed(() => (props.selectedItemsLength
      ? props.selectedItemsLength !== props.rowsLength : false));

    const toggleDropdown = () => {
      if (props.disabled) return;
      dropdownActive.value = !dropdownActive.value;
    };

    const checkboxClicked = () => {
      if (props.multiselect) {
        selectedItems.value = [];
        emit('selected:value', 'all');
        return;
      }
      selectedValue.value = '';
      emit('selected:value', 'all');
      dropdownActive.value = false;
    };

    const onValueSelected = (action) => {
      if (action.disabled) return;
      selectedValue.value = action.key;
      emit('selected:value', action.key);
    };

    onClickOutside(dropdownRoot, () => {
      dropdownActive.value = false;
    });

    return {
      dropdownActive,
      toggleDropdown,
      selectedValue,
      modelValue,
      checkboxClicked,
      dropdownRoot,
      onValueSelected,
      checkboxIntermediate,
    };
  },
};
</script>

<style lang="scss">
.checkbox-dropdown--root {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 4px;
    border-radius: 4px;
    cursor: pointer;

    &:hover, &.active {
      background-color: $gray400;
    }

    .chevron-icon {
      stroke: $gray600;
      margin-left: 7px;
    }

    .dropdown-root {
      min-width: 200px;
      display: none;
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      padding: 4px 0px;
      background: #fff;
      box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      color: $black;
      z-index: 1;
      max-height: 300px;
      overflow-y: auto;

      &.active {
          display: block;
      }

      .dropdown-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 8px;

        &:hover, &.active {
          background-color: $gray300;
        }
        &.disabled {
          cursor: not-allowed;
          color: $gray600;
        }

        .icon {
          svg {
            path {
              stroke: #191414;
            }
          }
        }
      }
    }
}
</style>
